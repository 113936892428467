import React from 'react'
import HomeHeader from "../components/homepage-header"
import AniLink from "gatsby-plugin-transition-link/AniLink/index"
import Footer from "../components/footer"
import Header from "../components/header"
import SanitizeHTML from "react-sanitized-html"

const AboutPage = (props) => {
  const pages = props.pageContext.pages;

  let page = {};
  if(!!pages) {
    pages.forEach((item) => {
      if (item.page_id === '2') {
        page = item;
      }
    })
  }

  return (
    <main style={{backgroundColor: page.backgroundColor}}>
      <Header isAbout={true} {...props}/>

      <div className="container-fluid single-project">
        <article>
          <div className="row">
            <div className="col-md-8">
              <h1>{page.title}</h1>

              <SanitizeHTML  html={page.content}/>
            </div>
          </div>
        </article>
      </div>

      <Footer {...props} />
    </main>
  )
}

export default AboutPage